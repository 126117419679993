import { t } from "./Localization.js";
import { createElement } from "react";
import { Img_logoNew, Img_borderFooter } from "./Css.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "./Communication.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { infoForMobile, infoForDesktop } from "./Haraldssons/HaraldssonsFooter.js";
import { singleton, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function p(key) {
    return t("schedule." + key);
}

export const footerBorder = createElement("div", {
    className: "isCustomBorderVisible",
    style: {
        backgroundImage: ("url(\u0027" + Img_borderFooter) + "\u0027)",
        marginBottom: -10,
        width: 100 + "%",
        height: 70,
        position: "relative",
        marginTop: -60,
    },
});

export function Footer() {
    let props_26, props, props_2, props_16, props_6, props_10, props_14, props_23, props_18, props_21;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    switch (settings.tag) {
        case 1: {
            return null;
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            const set$ = settings.fields[0];
            const props_28 = singleton(["children", Interop_reactApi.Children.toArray([footerBorder, (props_26 = ofArray([["style", {
                backgroundColor: "#1f1f1f",
            }], ["className", join(" ", ["is-fluid", "footer-container-padding"])], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
                paddingTop: 30,
            }], ["className", join(" ", ["is-hidden-mobile", "is-hidden-tablet-only"])], ["children", Interop_reactApi.Children.toArray([infoForDesktop(set$)])]]), createElement("div", createObj(Helpers_combineClasses("columns", props)))), (props_2 = ofArray([["style", {
                paddingTop: 30,
            }], ["className", "is-mobile"], ["className", join(" ", ["is-hidden-desktop"])], ["children", Interop_reactApi.Children.toArray([infoForMobile(set$)])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_2)))), (props_16 = ofArray([["className", "py-5"], ["style", {
                margin: 0,
                backgroundColor: "#1f1f1f",
                width: 100 + "%",
                borderTopStyle: "solid",
                borderWidth: 2,
                borderColor: "#4A4A4A",
                fontSize: 18 + "px",
                fontWeight: 400,
            }], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-4"], ["className", join(" ", ["px-0"])], ["style", {
                display: "flex",
                alignItems: "center",
            }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["icon", "fa", "fa-map-marker-alt", "fa-2x", "iconInFooter"]),
            }), createElement("a", {
                className: join(" ", ["footer-contacts"]),
                href: set$.GoogleLink,
                target: "_blank",
                children: Interop_reactApi.Children.toArray([set$.Address]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_10 = ofArray([["className", join(" ", ["px-0"])], ["style", {
                display: "flex",
                alignItems: "center",
            }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["icon", "fa", "fa-phone", "fa-2x", "iconInFooter"]),
            }), createElement("p", {
                className: join(" ", ["footer-contacts"]),
                children: set$.Phone,
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_14 = ofArray([["className", join(" ", ["px-0"])], ["style", {
                display: "flex",
                alignItems: "center",
            }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["icon", "fa", "fa-envelope", "fa-2x", "iconInFooter"]),
            }), createElement("a", {
                className: join(" ", ["footer-contacts"]),
                href: "mailto:" + set$.Email,
                children: Interop_reactApi.Children.toArray([set$.Email]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_16)))), createElement("a", {
                href: "https://www.bidflow.com/",
                target: "_blank",
                children: Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "py-2"], ["className", "is-mobile"], ["style", {
                    margin: 0,
                    backgroundColor: "#1f1f1f",
                    width: 100 + "%",
                    borderTopStyle: "solid",
                    borderWidth: 2,
                    borderColor: "#4A4A4A",
                    alignItems: "center",
                    cursor: "pointer",
                }], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-narrow"], ["style", {
                    fontSize: 10 + "px",
                    color: "#FFFFFF",
                    padding: 0,
                }], ["className", "is-uppercase"], ["children", "powered by"]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), (props_21 = ofArray([["className", "is-narrow"], ["style", {
                    width: 100,
                    height: 50,
                    padding: 0,
                    marginLeft: 20,
                    alignItems: "center",
                    display: "flex",
                }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
                    className: "footerImgBidflow",
                    src: Img_logoNew,
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_23))))]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_26))))])]);
            return createElement("footer", createObj(Helpers_combineClasses("footer", props_28)));
        }
        default: {
            return null;
        }
    }
}

