import { tWithArgs, t } from "../Localization.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred$1 } from "../Extensions.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Img_contactBackgroundHaraldssons } from "../Css.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { collect, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { singleton as singleton_2, indexed, length, head, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { CustomerSpecific, Route, toPath } from "../Router.js";
import { scrollableAnchor } from "../Components/ScrollableAnchor.js";
import { medium } from "../Components/Loader.js";

export function p(key) {
    return t("haraldssons.buy." + key);
}

export function Render(renderInputProps) {
    let props_46, props_44, props_34, props_42;
    const user = renderInputProps.user;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const symbol = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const intervals = patternInput_1[0];
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(homeApi().getBiddingIntervals(), (_arg1) => singleton.Bind(homeApi().getCurrencySettings(), (_arg2) => {
            patternInput_1[1](new Deferred$1(2, _arg1));
            patternInput[1](_arg2.CurrencySymbol);
            return singleton.Return();
        }))));
    });
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "previewImageCenterWithoutRatio",
            style: {
                display: "flex",
                justifyContent: "center",
                backgroundImage: ("url(\u0027" + Img_contactBackgroundHaraldssons) + "\u0027)",
                minHeight: 400,
                alignItems: "center",
                textAlign: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    textAlign: "center",
                    display: "grid",
                    justifyContent: "center",
                },
                className: join(" ", ["buyBox"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props;
                    return append(singleton_1((props = ofArray([["className", "is-size-2"], ["className", "has-text-white"], ["className", "homeTitleAuction"], ["children", p("head.title")]]), createElement("h1", createObj(Helpers_combineClasses("title", props))))), delay(() => append(singleton_1(createElement("h2", {
                        children: p("head.subtitle"),
                        className: join(" ", ["buy-text-h2"]),
                    })), delay(() => {
                        let props_5, props_3;
                        return (user.tag === 1) ? singleton_1((props_5 = ofArray([["style", {
                            width: 40 + "%",
                        }], ["className", join(" ", ["buy-box-button"])], ["className", "is-light"], ["href", toPath(new Route(11))], ["children", t("buyPage.button.profile")]]), createElement("a", createObj(Helpers_combineClasses("button", props_5))))) : singleton_1((props_3 = ofArray([["style", {
                            width: 40 + "%",
                        }], ["className", join(" ", ["buy-box-button"])], ["className", "is-light"], ["href", toPath(new Route(10))], ["children", t("buyPage.head.button.createAccount")]]), createElement("a", createObj(Helpers_combineClasses("button", props_3)))));
                    }))));
                })))),
            })]),
        }), (props_46 = ofArray([["style", {
            whiteSpace: "pre-line",
        }], ["children", Interop_reactApi.Children.toArray([(props_44 = ofArray([["className", join(" ", ["buy-content-box"])], ["style", {
            paddingBottom: 30,
        }], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "is-9"], ["className", "is-6-fullhd"], ["className", "is-6-widescreen"], ["className", "is-9-desktop"], ["className", "is-11-tablet"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: "buyBox",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("anchor.title"),
                className: join(" ", ["buy-title", "homeTitleAuction"]),
            }), createElement("p", {
                children: p("anchor.subtitle"),
                className: "buy-text",
            })]),
        }), createElement("div", {
            className: join(" ", ["buttons"]),
            children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(scrollableAnchor, {
                className: join(" ", ["button", "buy-button-small"]),
                children: p("anchor.buyersFees"),
                href: "#buyersFees",
            }), Interop_reactApi.createElement(scrollableAnchor, {
                className: join(" ", ["button", "buy-button-small"]),
                children: p("anchor.payment"),
                href: "#payment",
            }), Interop_reactApi.createElement(scrollableAnchor, {
                className: join(" ", ["button", "buy-button-small"]),
                children: p("anchor.shipping"),
                href: "#shipping",
            }), Interop_reactApi.createElement(scrollableAnchor, {
                className: join(" ", ["button", "buy-button-small"]),
                children: p("anchor.collection"),
                href: "#collection",
            })]),
        }), createElement("div", {
            className: "buyBox",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("box1.title"),
                className: join(" ", ["buy-title", "homeTitleAuction"]),
            }), createElement("p", {
                children: p("box1.text"),
                className: "buy-text",
            }), createElement("a", {
                style: {
                    textDecoration: "underline",
                },
                href: toPath(new Route(24, new CustomerSpecific(3))),
                children: p("box1.link"),
                className: "buy-text",
            })]),
        }), createElement("div", {
            className: join(" ", ["buyBox", "grayBackground"]),
            id: "buyersFees",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("buyersFees.title"),
                className: join(" ", ["buy-title", "homeTitleAuction"]),
            }), createElement("p", {
                children: p("buyersFees.text"),
                className: "buy-text",
            }), createElement("a", {
                style: {
                    textDecoration: "underline",
                },
                href: toPath(new Route(24, new CustomerSpecific(3))),
                children: p("buyersFees.link"),
                className: "buy-text",
            })]),
        }), createElement("div", {
            className: join(" ", ["buyBox"]),
            id: "payment",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("payment.title"),
                className: join(" ", ["buy-title", "homeTitleAuction"]),
            }), createElement("p", {
                children: p("payment.text"),
                className: "buy-text",
            })]),
        }), createElement("div", {
            className: join(" ", ["buyBox", "grayBackground"]),
            id: "collection",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("collection.title"),
                className: join(" ", ["buy-title", "homeTitleAuction"]),
            }), createElement("p", {
                children: p("collection.text"),
                className: "buy-text",
            })]),
        }), createElement("div", {
            className: join(" ", ["buyBox"]),
            id: "shipping",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("shipping.title"),
                className: join(" ", ["buy-title", "homeTitleAuction"]),
            }), createElement("p", {
                children: p("shipping.text"),
                className: "buy-text",
            })]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_34)))), (props_42 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["buy-content-container-green", "buyContainer"]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", {
                children: t("buyPage.box.title"),
                className: join(" ", ["is-size-3-mobile", "is-size-2", "homeTitleAuction"]),
                style: {
                    marginBottom: 10,
                    paddingBottom: 15,
                    borderWidth: 3,
                    borderBottomStyle: "solid",
                },
            })), delay(() => {
                switch (intervals.tag) {
                    case 1: {
                        return singleton_1(medium("Loading"));
                    }
                    case 2: {
                        const intervals_2 = intervals.fields[0];
                        return append(singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["children", tWithArgs("buyPage.box.subtitle", {
                            bid: `${head(intervals_2).From} ${symbol}`,
                        })]), delay(() => singleton_1(["className", join(" ", ["buy-container-green-h2", "homeTitleAuction"])])))))))), delay(() => {
                            let children;
                            return singleton_1((children = toList(delay(() => collect((matchValue) => {
                                const interval = matchValue[1];
                                return singleton_1(createElement("li", {
                                    className: join(" ", ["buy-table-list", "buyBorder"]),
                                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((matchValue[0] !== (length(intervals_2) - 1)) ? singleton_1(`${interval.From} ${symbol} - ${interval.To} ${symbol}`) : singleton_1(`${interval.From} ${symbol} - `), delay(() => singleton_1(createElement("span", {
                                        children: `${interval.BidSize} ${symbol}`,
                                        style: {
                                            float: "right",
                                        },
                                    })))))))),
                                }));
                            }, indexed(intervals_2)))), createElement("ul", {
                                children: Interop_reactApi.Children.toArray(Array.from(children)),
                            })));
                        }));
                    }
                    default: {
                        return singleton_1(medium("Loading"));
                    }
                }
            })))))),
        })])]), createElement("div", createObj(Helpers_combineClasses("column", props_42))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_46))))]),
    });
}

