import { t } from "../Localization.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { Settings_CompanySettingsInfo_get_empty } from "../Shared/Shared.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function p(key) {
    return t("haraldssons.home." + key);
}

export function Render() {
    let props_4;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    const set$_1 = (settings.tag === 2) ? settings.fields[0] : Settings_CompanySettingsInfo_get_empty();
    const children = singleton((props_4 = ofArray([["className", join(" ", ["paddingLeftRight-mobile45", "paddingBottom-desktop80-mobile10"])], ["style", {
        backgroundColor: "#efefef",
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([createElement("h2", {
        className: join(" ", ["paddingTop-desktop80-mobile10", "home-info-white-space-title", "homeTitleAuction"]),
        children: t("haraldssons.home.follow"),
    }), createElement("div", {
        className: join(" ", ["is-centered"]),
        style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
        },
        children: Interop_reactApi.Children.toArray([createElement("a", {
            className: join(" ", ["socialHome", "icon", "fab", "fa-facebook-f", "fa-2x", "home-social-media-icon"]),
            href: set$_1.FacebookLink,
        }), createElement("a", {
            className: join(" ", ["socialHome", "icon", "fab", "fa-instagram", "fa-2x", "home-social-media-icon"]),
            href: set$_1.InstagramLink,
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_4)))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

