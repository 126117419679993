import { t } from "../Localization.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { large } from "../Components/Loader.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Img_contactBackgroundHaraldssons } from "../Css.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Schedule } from "./HaraldssonsFooter.js";

export function p(s) {
    return t("haraldssons.contacts." + s);
}

export function Render() {
    let props_1, props_14, props_6, props_12;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    switch (settings.tag) {
        case 1: {
            return large;
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            const set$ = settings.fields[0];
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["contact-img-top"]),
                    style: {
                        backgroundImage: ("url(\u0027" + Img_contactBackgroundHaraldssons) + "\u0027)",
                        display: "flex",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["is-size-3-mobile", "is-size-1", "contactText"])], ["children", Interop_reactApi.Children.toArray(["Haraldssons", createElement("br", {}), "Auktioner"])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))]),
                }), createElement("div", {
                    className: join(" ", ["contact-info-box"]),
                    children: Interop_reactApi.Children.toArray([(props_14 = ofArray([["style", {
                        margin: 0,
                        justifyContent: "space-between",
                    }], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-6-fullhd"], ["className", "is-7-desktop"], ["className", "is-6-tablet"], ["className", join(" ", ["contact-info-text-on-white"])], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                        style: {
                            whiteSpace: "pre-line",
                            paddingBottom: 60,
                        },
                        children: p("text"),
                    }), createElement("iframe", {
                        preload: "metadata",
                        style: {
                            width: 100 + "%",
                            height: 100 + "%",
                            minHeight: 300,
                            maxHeight: 350,
                        },
                        src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2252.0920547860233!2d13.69008377734662!3d55.635212673040876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465477e151161257%3A0x74eb2d54bced1157!2sHaraldssons%20Auctions!5e0!3m2!1sse!2sse!4v1693300290139!5m2!1sse!2sse",
                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_12 = singleton(["children", Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", ["contact-info-text-on-grey", "contactGreyBox"]),
                        style: {
                            lineHeight: 1.1 + "em",
                        },
                        children: Interop_reactApi.Children.toArray([set$.Phone, createElement("br", {}), set$.Email, createElement("br", {}), set$.Address, createElement("br", {}), createElement(Schedule, null)]),
                    })])]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_14))))]),
                })]),
            });
        }
        default: {
            return large;
        }
    }
}

