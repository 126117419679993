import { t } from "../Localization.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ImageUrlModule_toString } from "../bidflow/src/infrastructure/Auction.js";
import { Img_originalImageUrlInPng } from "../Css.js";
import { CookiesButton } from "../Cookies.js";
import { Route, CustomerSpecific, toPath } from "../Router.js";

export function p(key) {
    return t("haraldssons.schedule." + key);
}

export function Schedule() {
    let props, props_6, props_2, props_4, props_12, props_8, props_10, props_18, props_14, props_16, props_24, props_20, props_22, props_30, props_26, props_28, props_36, props_32, props_34, props_42, props_38, props_40;
    const props_44 = ofArray([["className", "schedule-container-width"], ["style", {
        fontFamily: "Roboto Slab",
    }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", join(" ", ["is-size-7-mobile"])], ["style", {
        textAlign: "left",
        color: "#FFFFFF",
        paddingTop: 0,
        paddingLeft: 0,
        fontSize: 20 + "px",
    }], ["className", "has-text-weight-bold"], ["children", p("office.name")]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_6 = ofArray([["className", join(" ", ["is-mobile", "is-size-6-mobile"])], ["style", {
        marginBottom: 0,
        fontWeight: 400,
        color: "#BDBDBD",
        fontSize: 14 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", join(" ", ["py-2", "is-three-fifths"])], ["style", {
        textAlign: "left",
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work.days")]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "py-2"], ["style", {
        textAlign: "right",
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work.time")]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6)))), (props_12 = ofArray([["className", join(" ", ["is-mobile", "is-size-6-mobile"])], ["style", {
        marginBottom: 0,
        fontWeight: 400,
        color: "#BDBDBD",
        fontSize: 14 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["py-2", "is-three-fifths"])], ["style", {
        textAlign: "left",
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work2.days")]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "py-2"], ["style", {
        textAlign: "right",
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work2.time")]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12)))), (props_18 = ofArray([["className", join(" ", ["is-mobile", "py-0", "is-size-6-mobile"])], ["style", {
        marginBottom: 0,
        fontWeight: 400,
        color: "#d66363",
        fontSize: 14 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "py-2"], ["style", {
        textAlign: "left",
        paddingLeft: 0,
    }], ["children", p("office.work3.days")]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "py-2"], ["style", {
        textAlign: "right",
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work3.time")]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18)))), (props_24 = ofArray([["className", join(" ", ["is-mobile", "is-size-6-mobile"])], ["style", {
        marginBottom: 0,
        fontWeight: 400,
        color: "#BDBDBD",
        fontSize: 14 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", join(" ", ["py-2", "is-three-fifths"])], ["style", {
        textAlign: "left",
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work4.days")]]), createElement("div", createObj(Helpers_combineClasses("column", props_20)))), (props_22 = ofArray([["className", "py-2"], ["style", {
        textAlign: "right",
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work4.time")]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24)))), (props_30 = ofArray([["className", join(" ", ["is-mobile", "is-size-6-mobile"])], ["style", {
        marginBottom: 0,
        fontWeight: 400,
        color: "#BDBDBD",
        fontSize: 14 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["className", join(" ", ["py-2", "is-three-fifths"])], ["style", {
        textAlign: "left",
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work5.days")]]), createElement("div", createObj(Helpers_combineClasses("column", props_26)))), (props_28 = ofArray([["className", "py-2"], ["style", {
        textAlign: "right",
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work5.time")]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_30)))), (props_36 = ofArray([["className", join(" ", ["is-mobile", "py-0", "is-size-6-mobile"])], ["style", {
        marginBottom: 0,
        fontWeight: 400,
        color: "#d66363",
        fontSize: 14 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_32 = ofArray([["className", "py-2"], ["style", {
        textAlign: "left",
        paddingLeft: 0,
    }], ["children", p("office.work6.days")]]), createElement("div", createObj(Helpers_combineClasses("column", props_32)))), (props_34 = ofArray([["className", "py-2"], ["style", {
        textAlign: "right",
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work6.time")]]), createElement("div", createObj(Helpers_combineClasses("column", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_36)))), (props_42 = ofArray([["className", join(" ", ["is-mobile", "py-0", "is-size-6-mobile"])], ["style", {
        marginBottom: 0,
        fontWeight: 400,
        color: "#d66363",
        fontSize: 14 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_38 = ofArray([["className", "py-2"], ["style", {
        textAlign: "left",
        paddingLeft: 0,
    }], ["children", p("office.work7.days")]]), createElement("div", createObj(Helpers_combineClasses("column", props_38)))), (props_40 = ofArray([["className", "py-2"], ["style", {
        textAlign: "right",
        paddingRight: 0,
        paddingLeft: 0,
    }], ["children", p("office.work7.time")]]), createElement("div", createObj(Helpers_combineClasses("column", props_40))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_42))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_44)));
}

export function infoForDesktop(settings) {
    let props_8, value_19, props_10, props_17, props_14;
    return react.createElement(react.Fragment, {}, (props_8 = ofArray([["className", "is-one-third"], ["style", {
        fontSize: 15 + "px",
        color: "#BDBDBD",
        display: "flex",
        flexDirection: "column",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            width: 60 + "%",
        },
        className: "footer-logo",
        children: Interop_reactApi.Children.toArray([createElement("img", {
            className: "footer-logo",
            src: ImageUrlModule_toString(Img_originalImageUrlInPng(settings.Logo)),
        })]),
    }), createElement("p", {
        children: [settings.CompanyName],
    }), (value_19 = ("Org.nr: " + settings.OrgNumber), createElement("p", {
        children: [value_19],
    })), createElement("p", {
        children: ["© Haraldssons Auktioner 2023"],
    }), createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("a", {
            style: {
                color: "#FFFFFF",
                margin: ((20 + "px ") + 20) + "px",
                borderRadius: 50 + "%",
                width: 38,
                height: 38,
            },
            className: join(" ", ["social", "icon", "fab", "fa-facebook-f", "fa-lg"]),
            href: settings.FacebookLink,
        }), createElement("a", {
            style: {
                color: "#FFFFFF",
                margin: ((20 + "px ") + 20) + "px",
                borderRadius: 50 + "%",
                width: 38,
                height: 38,
            },
            className: join(" ", ["social", "icon", "fab", "fa-instagram", "fa-lg"]),
            href: settings.InstagramLink,
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["style", {
        marginBottom: 0,
        maxWidth: 320,
    }], ["className", join(" ", ["is-family-secondary", "is-one-third-fullhd", "is-one-third-tablet"])], ["children", Interop_reactApi.Children.toArray([createElement(Schedule, null)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_17 = ofArray([["className", join(" ", ["is-one-quarter", "is-offset-1"])], ["style", {
        maxWidth: 300,
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: join(" ", ["margin-top-desktop20-mobile0", "footer-payment-providers-img"]),
    }), createElement(CookiesButton, null), createElement("div", {
        style: {
            marginBottom: 15,
        },
    }), createElement("div", {
        className: "footer-buttons-container",
        children: Interop_reactApi.Children.toArray([(props_14 = ofArray([["href", toPath(new Route(24, new CustomerSpecific(3)))], ["target", "_blank"], ["className", join(" ", ["button", "is-dark", "is-uppercase", "is-fullwidth", "footer-button"])], ["children", t("termsAndConditions.title")]]), createElement("a", createObj(Helpers_combineClasses("button", props_14))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17)))));
}

export function infoForMobile(settings) {
    let props_7, value_10, props_14, props_11;
    return react.createElement(react.Fragment, {}, (props_7 = ofArray([["className", join(" ", ["is-one-third", "fontSize-desktop15-mobile12"])], ["style", {
        color: "#BDBDBD",
        display: "flex",
        flexDirection: "column",
    }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
        className: "footer-logo",
        src: ImageUrlModule_toString(Img_originalImageUrlInPng(settings.Logo)),
    }), createElement("p", {
        children: [settings.CompanyName],
    }), (value_10 = ("Org.nr: " + settings.OrgNumber), createElement("p", {
        children: [value_10],
    })), createElement("p", {
        children: ["© Haraldssons Auktioner 2023"],
    }), createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("a", {
            style: {
                color: "#FFFFFF",
                margin: ((20 + "px ") + 20) + "px",
                borderRadius: 50 + "%",
                width: 38,
                height: 38,
            },
            className: join(" ", ["social", "icon", "fab", "fa-facebook-f", "fa-lg", "footer-icons"]),
            href: settings.FacebookLink,
        }), createElement("a", {
            style: {
                color: "#FFFFFF",
                margin: ((20 + "px ") + 20) + "px",
                borderRadius: 50 + "%",
                width: 38,
                height: 38,
            },
            className: join(" ", ["social", "icon", "fab", "fa-instagram", "fa-lg", "footer-icons"]),
            href: settings.InstagramLink,
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_14 = ofArray([["style", {
        marginBottom: 0,
    }], ["className", join(" ", ["is-family-secondary", "is-one-quarter-fullhd", "footer-width"])], ["children", Interop_reactApi.Children.toArray([createElement(Schedule, null), createElement("div", {
        className: join(" ", ["margin-top-desktop20-mobile0", "footer-payment-providers-img"]),
    }), createElement(CookiesButton, null), createElement("div", {
        style: {
            marginBottom: 15,
        },
    }), createElement("div", {
        className: "footer-buttons-container",
        children: Interop_reactApi.Children.toArray([(props_11 = ofArray([["href", toPath(new Route(24, new CustomerSpecific(3)))], ["target", "_blank"], ["className", join(" ", ["button", "is-dark", "is-uppercase", "is-fullwidth", "footer-button"])], ["children", t("termsAndConditions.title")]]), createElement("a", createObj(Helpers_combineClasses("button", props_11))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))));
}

