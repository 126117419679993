import { t } from "../Localization.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";

export function p(key) {
    return t("haraldssons.termsAndConditions." + key);
}

export function Body() {
    let value_6, value_8, value_17, value_23, value_32, value_34, value_40, value_42, value_48, value_50, value_56, value_65, value_67, value_73, value_82, value_91, value_100, value_109, value_118, value_127, value_136, value_145, value_154;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                paddingTop: 20,
                whiteSpace: "pre-line",
            },
            className: join(" ", ["has-background-white", "has-text-grey-darker"]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_6 = p("subtitle.1"), createElement("h1", {
                        children: [value_6],
                    }))]),
                }), (value_8 = p("text.1"), createElement("p", {
                    children: [value_8],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([createElement("h1", {
                        children: p("subtitle.2"),
                    })]),
                }), (value_17 = p("text.2"), createElement("p", {
                    children: [value_17],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_23 = p("subtitle.3"), createElement("h1", {
                        children: [value_23],
                    }))]),
                }), createElement("p", {
                    children: p("text.3"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_32 = p("subtitle.4"), createElement("h1", {
                        children: [value_32],
                    }))]),
                }), (value_34 = p("text.4"), createElement("p", {
                    children: [value_34],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_40 = p("subtitle.5"), createElement("h1", {
                        children: [value_40],
                    }))]),
                }), (value_42 = p("text.5"), createElement("p", {
                    children: [value_42],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_48 = p("subtitle.6"), createElement("h1", {
                        children: [value_48],
                    }))]),
                }), (value_50 = p("text.6"), createElement("span", {
                    children: [value_50],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_56 = p("subtitle.7"), createElement("h1", {
                        children: [value_56],
                    }))]),
                }), createElement("span", {
                    children: p("text.7"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_65 = p("subtitle.8"), createElement("h1", {
                        children: [value_65],
                    }))]),
                }), (value_67 = p("text.8"), createElement("p", {
                    children: [value_67],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_73 = p("subtitle.9"), createElement("h1", {
                        children: [value_73],
                    }))]),
                }), createElement("p", {
                    children: p("text.9"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_82 = p("subtitle.10"), createElement("h1", {
                        children: [value_82],
                    }))]),
                }), createElement("span", {
                    children: p("text.10"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_91 = p("subtitle.11"), createElement("h1", {
                        children: [value_91],
                    }))]),
                }), createElement("span", {
                    children: p("text.11"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_100 = p("subtitle.12"), createElement("h1", {
                        children: [value_100],
                    }))]),
                }), createElement("span", {
                    children: p("text.12"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_109 = p("subtitle.13"), createElement("h1", {
                        children: [value_109],
                    }))]),
                }), createElement("span", {
                    children: p("text.13"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_118 = p("subtitle.14"), createElement("h1", {
                        children: [value_118],
                    }))]),
                }), createElement("span", {
                    children: p("text.14"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_127 = p("subtitle.15"), createElement("h1", {
                        children: [value_127],
                    }))]),
                }), createElement("span", {
                    children: p("text.15"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_136 = p("subtitle.16"), createElement("h1", {
                        children: [value_136],
                    }))]),
                }), createElement("span", {
                    children: p("text.16"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_145 = p("subtitle.17"), createElement("h1", {
                        children: [value_145],
                    }))]),
                }), createElement("span", {
                    children: p("text.17"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_154 = p("subtitle.18"), createElement("h1", {
                        children: [value_154],
                    }))]),
                }), createElement("span", {
                    children: p("text.18"),
                })]),
            })]),
        })]),
    });
}

export function Render() {
    return createElement("div", {
        style: {
            padding: ((((((60 + "px ") + 60) + "px ") + 120) + "px ") + 60) + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement(Body, null)]),
    });
}

