import { tWithArgs, Localized_formatDate, Localized_translate, t } from "./Localization.js";
import { Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { union_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { State__Elapsed, State__ElapsedSeconds, update, init } from "./Components/Timer.js";
import { minValue, toLocalTime, op_Subtraction, addSeconds, now } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { totalSeconds, seconds as seconds_1, minutes as minutes_1, hours as hours_1, days as days_1 } from "./fable_modules/fable-library.3.6.1/TimeSpan.js";
import { createElement } from "react";
import * as react from "react";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { AuctionPreviewInfo_get_Empty, Resource$1, SearchCriteria, Auction, HomeInfo_ContentField, StatusAuction } from "./Shared/Shared.js";
import { collect, iterate, append as append_1, empty, singleton, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Render } from "./Haraldssons/HaraldssonsHome.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "./Communication.js";
import { tryFind, indexed, truncate, length, append, map, ofArray, singleton as singleton_1, tryHead, isEmpty } from "./fable_modules/fable-library.3.6.1/List.js";
import { replace, isNullOrEmpty, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { largeGreen, large } from "./Components/Loader.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { promotion } from "./Promotions.js";
import { ImageUrlModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { Img_originalImagePath, Img_startBackground, Img_originalImageUrlInPng } from "./Css.js";
import { LocalizedMap_getValueOrEmpty } from "./bidflow/src/infrastructure/Localization.js";
import { AuctionState, Route, toPath } from "./Router.js";
import { equals as equals_1 } from "./fable_modules/fable-library.3.6.1/Date.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { MediaQuery_customBreakpoints } from "./Common.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { Responsive, Settings } from "./Components/Slider.js";
import react_slick from "react-slick";
import { map as map_1, defaultArg, toArray } from "./fable_modules/fable-library.3.6.1/Option.js";

export function p(key) {
    return t("home." + key);
}

export class PreviewSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Big", "Middle", "Small", "Mobile"];
    }
}

export function PreviewSize$reflection() {
    return union_type("Home.PreviewSize", [], PreviewSize, () => [[], [], [], []]);
}

export class WeeklySpecialsButtonDisplay extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Yes", "No"];
    }
}

export function WeeklySpecialsButtonDisplay$reflection() {
    return union_type("Home.WeeklySpecialsButtonDisplay", [], WeeklySpecialsButtonDisplay, () => [[], []]);
}

export function Timer(timerInputProps) {
    let copyOfStruct, copyOfStruct_1, copyOfStruct_2, copyOfStruct_3, copyOfStruct_4;
    const time = timerInputProps.time;
    const state_1 = useFeliz_React__React_useElmish_Static_17DC4F1D(init((copyOfStruct = now(), addSeconds(copyOfStruct, time)), 1000), (msg, state) => update(msg, state), [time])[0];
    if (State__ElapsedSeconds(state_1) < 0) {
        return null;
    }
    else {
        const patternInput_1 = [(copyOfStruct_1 = State__Elapsed(state_1), days_1(copyOfStruct_1)), (copyOfStruct_2 = State__Elapsed(state_1), hours_1(copyOfStruct_2)), (copyOfStruct_3 = State__Elapsed(state_1), minutes_1(copyOfStruct_3)), (copyOfStruct_4 = State__Elapsed(state_1), seconds_1(copyOfStruct_4))];
        const s = patternInput_1[3] | 0;
        const m = patternInput_1[2] | 0;
        const h = patternInput_1[1] | 0;
        const d = patternInput_1[0] | 0;
        const days = (d >= 0) ? (`${d} ${p("days")}`) : "";
        const hours = (h >= 0) ? (`${h} ${p("hours")}`) : "";
        const minutes = (m >= 0) ? (`${m} ${p("minutes")}`) : "";
        const seconds = (s >= 0) ? (`${s} ${p("seconds")}`) : "";
        return createElement("span", {
            children: `${days} ${hours} ${minutes} ${seconds}`,
        });
    }
}

export function viewTimer(auctionStatus, auctionDate, flag) {
    let copyOfStruct;
    if (equals(auctionStatus, new StatusAuction(1))) {
        return createElement("span", {
            className: "home-info-subtitle-medium-size",
            style: createObj(toList(delay(() => (flag ? singleton(["color", "#FFFFFF"]) : empty())))),
            children: Interop_reactApi.Children.toArray([createElement(Timer, {
                time: ~(~((copyOfStruct = op_Subtraction(auctionDate, now()), totalSeconds(copyOfStruct)))),
            })]),
        });
    }
    else {
        return null;
    }
}

export const customBlock = createElement(Render, null);

export function ViewHomeInfo(viewHomeInfoInputProps) {
    let error, content_1, list2, content, props_17;
    const auc = viewHomeInfoInputProps.auc;
    const user = viewHomeInfoInputProps.user;
    const info = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getHomeInfo(), []);
    const lastAuction = (!isEmpty(auc.ActiveAuctions)) ? tryHead(auc.ActiveAuctions) : tryHead(auc.HistoryAuctions);
    const props_19 = ofArray([["className", join(" ", ["home-box-container", "is-fluid", "px-0", "mx-0", "has-text-centered", "is-mobile", "is-overlay", "home-description-box"])], ["children", Interop_reactApi.Children.toArray(Array.from((info.tag === 1) ? singleton_1(large) : ((info.tag === 3) ? singleton_1((error = info.fields[0].message, isNullOrEmpty(error) ? null : createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", error]])))))) : ((info.tag === 2) ? ((content_1 = info.fields[0], (list2 = map((promo) => promotion(promo), content_1.Promotions), append((content = content_1, singleton_1((props_17 = ofArray([["className", join(" ", ["home-description-box-padding-top", "margin-top-desktop40-mobile20", "margin-bottom-desktop80-mobile60"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let matchValue, i, props_3;
        return append_1((matchValue = content.Home.Image, (matchValue != null) ? ((i = matchValue, singleton((props_3 = ofArray([["style", {
            marginBottom: 20,
        }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
            className: "previewImageCenterWithoutRatio",
            src: ImageUrlModule_toString(Img_originalImageUrlInPng(i)),
            alt: "Placeholder image",
        })])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_3))))))) : singleton(null)), delay(() => {
            let props_9, props_7;
            return append_1(singleton((props_9 = ofArray([["className", "is-centered"], ["className", "home-description-box-padding-top"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", join(" ", ["paddingLeftRight-mobile45", "is-9-tablet", "is-9-desktop"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("h2", {
                className: join(" ", ["home-info-white-space-title", "homeTitleAuction"]),
                children: Localized_translate(LocalizedMap_getValueOrEmpty(new HomeInfo_ContentField(0), content.Home.Content)),
            })), delay(() => {
                const homeText = Localized_translate(LocalizedMap_getValueOrEmpty(new HomeInfo_ContentField(1), content.Home.Content));
                if (lastAuction == null) {
                    return singleton(null);
                }
                else {
                    const auc_1 = lastAuction;
                    return singleton(createElement("p", {
                        className: join(" ", ["home-info-white-space-subtitle"]),
                        children: replace(replace(replace(homeText, "%DAYOFWEEK%", Localized_formatDate("EEEE", "EEEE", auc_1.Date)), "%DATE%", Localized_formatDate("d MMMM yyyy", "d MMMM yyyy", auc_1.Date)), "%TIME%", Localized_formatDate("HH:mm", "HH.mm", auc_1.Date)),
                    }));
                }
            }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_9))))), delay(() => {
                let props_15;
                return singleton((props_15 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_13, props_11;
                    return (user.tag === 1) ? singleton((props_13 = ofArray([["className", join(" ", ["home-info-white-button"])], ["className", "is-primary"], ["children", p("button.profile")], ["href", toPath(new Route(11))]]), createElement("a", createObj(Helpers_combineClasses("button", props_13))))) : singleton((props_11 = ofArray([["className", join(" ", ["home-info-white-button"])], ["className", "is-primary"], ["children", p("welcome.button")], ["href", toPath(new Route(10))]]), createElement("a", createObj(Helpers_combineClasses("button", props_11)))));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_15)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("container", props_17)))))), list2)))) : singleton_1(large)))))]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_19)));
}

export function toAuctionType(auctionPreview) {
    return new Auction(auctionPreview.Id, auctionPreview.Date, auctionPreview.PublishDate, auctionPreview.Status);
}

export function ViewButton(viewButtonInputProps) {
    let props_8, auction_1, x, props, auction_2, x_1, props_4;
    const auction = viewButtonInputProps.auction;
    const whiteButtonClass = "white-btn";
    return react.createElement(react.Fragment, {}, (props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(auction_1 = auction, (auction_1.tag === 1) ? ((auction_1.fields[0] != null) ? ((x = auction_1.fields[0], createElement("a", {
        href: toPath(new Route(3, new AuctionState(x.Id, 1, new SearchCriteria(0)))),
        className: join(" ", ["is-fullwidth", whiteButtonClass]),
        children: p("button.catalogue"),
    }))) : null) : createElement("a", {
        className: join(" ", [whiteButtonClass, "is-fullwidth"]),
        children: Interop_reactApi.Children.toArray([(props = singleton_1(["className", join(" ", ["fas", "fa-spinner", "fa-pulse"])]), createElement("span", createObj(Helpers_combineClasses("icon", props))))]),
    })), (auction_2 = auction, (auction_2.tag === 1) ? ((auction_2.fields[0] != null) ? ((x_1 = auction_2.fields[0], x_1.IsPreviewPublished ? null : createElement("a", createObj(toList(delay(() => append_1(singleton(["className", join(" ", ["is-fullwidth", whiteButtonClass])]), delay(() => {
        const matchValue = x_1.Status;
        switch (matchValue.tag) {
            case 1:
            case 2: {
                return append_1(singleton(["children", p("button.toTheAuction")]), delay(() => singleton(["href", toPath(new Route(2, x_1.Id))])));
            }
            case 3: {
                return append_1(singleton(["children", p("button.auctionLive")]), delay(() => singleton(["href", toPath(new Route(15))])));
            }
            default: {
                return append_1(singleton(["children", p("button.auctionOff")]), delay(() => singleton(["style", {
                    cursor: "default",
                }])));
            }
        }
    })))))))) : null) : createElement("a", {
        className: join(" ", ["is-fullwidth", whiteButtonClass]),
        children: Interop_reactApi.Children.toArray([(props_4 = singleton_1(["className", join(" ", ["fas", "fa-spinner", "fa-pulse"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_4))))]),
    }))])]), createElement("div", createObj(Helpers_combineClasses("buttons", props_8)))));
}

export function ViewViewing(viewViewingInputProps) {
    let value_2, auction_1, auctionLoaded, props_6;
    const auction = viewViewingInputProps.auction;
    const notLoadedAuctionInfo = createElement("div", {
        className: "home-info-subtitle-medium-size",
        children: Interop_reactApi.Children.toArray([(value_2 = p("banner.subtitle"), createElement("span", {
            children: [value_2],
        })), createElement("span", createObj(toList(delay(() => singleton(["children", p("banner.thursday.time")]))))), createElement("span", createObj(toList(delay(() => singleton(["children", p("banner.sunday.time")])))))]),
    });
    return react.createElement(react.Fragment, {}, (auction_1 = auction, (auction_1.tag === 1) ? ((auction_1.fields[0] != null) ? ((auctionLoaded = auction_1.fields[0], createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            style: {
                color: "#000000",
            },
            className: join(" ", ["fa-light", "fa-location-dot"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("span", {
            className: "home-info-subtitle-medium-size",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let value_19;
                const viewing = Localized_translate(auctionLoaded.HeadInfo.Viewing);
                const viewingText1 = Localized_translate(auctionLoaded.HeadInfo.ViewingText1);
                const viewingText2 = Localized_translate(auctionLoaded.HeadInfo.ViewingText2);
                const matchValue = auctionLoaded.Status;
                switch (matchValue.tag) {
                    default: {
                        return singleton((value_19 = (`${viewing} ${viewingText1} ${viewingText2}`), createElement("span", {
                            children: [value_19],
                        })));
                    }
                }
            })))),
        })]),
    }))) : notLoadedAuctionInfo) : createElement("p", {
        className: join(" ", ["is-size-5-mobile", "is-size-4"]),
        children: p("loading"),
    })));
}

export function mainAuctionRender(auction) {
    return createElement("div", {
        className: "mainAuction",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "mainAuction_leftBlock",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                className: join(" ", ["homeTitleAuction", "home-info-title-big-size"]),
                children: Localized_translate(auction.HeadInfo.Head),
            }), createElement("p", {
                className: join(" ", ["homeTitleAuction", "overflowHidden", "home-info-subtitle-big-size"]),
                style: {
                    display: "block",
                },
                children: auction.IsPreviewPublished ? tWithArgs("home.comingSoonInMonth", {
                    month: Localized_formatDate("MMMM", "MMMM", toLocalTime(auction.Date)),
                }) : Localized_formatDate("d MMMM", "d MMMM", auction.Date),
            }), createElement(ViewViewing, {
                auction: new Resource$1(1, auction),
            }), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_3;
                    return append_1(equals(auction.Status, new StatusAuction(1)) ? singleton((props_3 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        style: {
                            color: "#000000",
                        },
                        className: join(" ", ["fal", "fa-calendar-alt"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))) : singleton(null), delay(() => singleton(viewTimer(auction.Status, auction.Date, false))));
                })))),
            }), createElement(ViewButton, {
                auction: new Resource$1(1, auction),
            })]),
        }), createElement("div", {
            className: "previewImageRightWithoutRatio",
            style: createObj(toList(delay(() => {
                const matchValue = auction.Image;
                return (matchValue == null) ? singleton(["backgroundImage", ("url(\u0027" + Img_startBackground) + "\u0027)"]) : singleton(["backgroundImage", ("url(\u0027" + ImageUrlModule_toString(Img_originalImagePath(matchValue))) + "\u0027)"]);
            }))),
        })]),
    });
}

export function PreviewAuctionsBarItem(previewAuctionsBarItemInputProps) {
    const setIndex = previewAuctionsBarItemInputProps.setIndex;
    const ind = previewAuctionsBarItemInputProps.ind;
    const index = previewAuctionsBarItemInputProps.index;
    const auction = previewAuctionsBarItemInputProps.auction;
    return createElement("div", createObj(toList(delay(() => {
        const preview = (equals(auction.Status, new StatusAuction(2)) ? auction.IsPreviewPublished : false) ? (!equals_1(auction.Date, minValue())) : false;
        return append_1(singleton(["className", join(" ", toList(delay(() => append_1(singleton("previewAuctionsBarBox_item"), delay(() => ((index === ind) ? singleton("previewAuctionsBarBox_triangle") : empty()))))))]), delay(() => append_1(singleton(["onClick", (_arg1) => {
            setIndex(ind);
        }]), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["previewImageRightWithoutRatio", "previewAuctionsBarItem_image"]),
            style: createObj(toList(delay(() => {
                const matchValue = auction.Image;
                return (matchValue == null) ? singleton(["backgroundImage", ("url(\u0027" + Img_startBackground) + "\u0027)"]) : singleton(["backgroundImage", ("url(\u0027" + ImageUrlModule_toString(Img_originalImagePath(matchValue))) + "\u0027)"]);
            }))),
        }), createElement("span", {
            className: join(" ", ["thumbnailAuctionStatus"]),
            children: Interop_reactApi.Children.toArray([createElement("i", {
                style: createObj(toList(delay(() => append_1(singleton(["fontSize", 8 + "px"]), delay(() => {
                    let matchValue_1;
                    return append_1((matchValue_1 = auction.Status, (matchValue_1.tag === 0) ? singleton(["color", "#c86a67"]) : ((matchValue_1.tag === 2) ? (auction.IsPreviewPublished ? singleton(["color", "#c8be67"]) : ((matchValue_1.tag === 2) ? singleton(["color", "#71ad6b"]) : ((matchValue_1.tag === 1) ? singleton(["color", "#71ad6b"]) : ((matchValue_1.tag === 3) ? singleton(["color", "#71ad6b"]) : (() => {
                        throw (new Error("Match failure: Bidflow.FO.Shared.StatusAuction"));
                    })())))) : ((matchValue_1.tag === 2) ? singleton(["color", "#71ad6b"]) : ((matchValue_1.tag === 1) ? singleton(["color", "#71ad6b"]) : ((matchValue_1.tag === 3) ? singleton(["color", "#71ad6b"]) : (() => {
                        throw (new Error("Match failure: Bidflow.FO.Shared.StatusAuction"));
                    })()))))), delay(() => singleton(["paddingRight", 10])));
                }))))),
                className: join(" ", ["fa", "fa-circle"]),
            }), createElement("span", createObj(toList(delay(() => append_1(singleton(["style", {
                textTransform: "uppercase",
            }]), delay(() => {
                let matchValue_2;
                return singleton(["children", (matchValue_2 = auction.Status, (matchValue_2.tag === 0) ? p("tag.completed") : ((matchValue_2.tag === 2) ? (auction.IsPreviewPublished ? p("tag.upcoming") : ((matchValue_2.tag === 2) ? p("tag.ongoing") : ((matchValue_2.tag === 1) ? p("tag.ongoing") : ((matchValue_2.tag === 3) ? p("tag.ongoing") : (() => {
                    throw (new Error("Match failure: Bidflow.FO.Shared.StatusAuction"));
                })())))) : ((matchValue_2.tag === 2) ? p("tag.ongoing") : ((matchValue_2.tag === 1) ? p("tag.ongoing") : ((matchValue_2.tag === 3) ? p("tag.ongoing") : (() => {
                    throw (new Error("Match failure: Bidflow.FO.Shared.StatusAuction"));
                })())))))]);
            }))))))]),
        }), createElement("p", {
            className: join(" ", ["homeTitleAuction", "overflowHidden", "home-info-title-medium-size"]),
            children: Localized_translate(auction.HeadInfo.Head),
        }), createElement("p", {
            className: join(" ", ["overflowHidden", "home-info-subtitle-medium-size"]),
            style: {
                display: "block",
                marginTop: 0,
            },
            children: preview ? tWithArgs("home.comingSoonInMonth", {
                month: Localized_formatDate("MMMM", "MMMM", toLocalTime(auction.Date)),
            }) : Localized_formatDate("d MMMM", "d MMMM", auction.Date),
        })])])))));
    }))));
}

export function getAuctions(multipleAuctions) {
    const his = (length(multipleAuctions.HistoryAuctions) >= 4) ? truncate(4, multipleAuctions.HistoryAuctions) : multipleAuctions.HistoryAuctions;
    if (length(multipleAuctions.ActiveAuctions) < 4) {
        return truncate(4, append(multipleAuctions.ActiveAuctions, his));
    }
    else {
        return multipleAuctions.ActiveAuctions;
    }
}

export function PrevArrow(prevArrowInputProps) {
    const setNextIndex = prevArrowInputProps.setNextIndex;
    const prev = prevArrowInputProps.prev;
    return createElement("div", {
        className: "slick-prev",
        children: Interop_reactApi.Children.toArray([createElement("i", {
            style: {
                alignSelf: "center",
            },
            className: join(" ", ["fa", "fa-angle-left"]),
        })]),
        onClick: (x) => {
            x.preventDefault();
            setNextIndex();
            prev();
        },
    });
}

export function NextArrow(nextArrowInputProps) {
    const setNextIndex = nextArrowInputProps.setNextIndex;
    const next = nextArrowInputProps.next;
    return createElement("div", {
        className: "slick-next",
        children: Interop_reactApi.Children.toArray([createElement("i", {
            style: {
                alignSelf: "center",
            },
            className: join(" ", ["fa", "fa-angle-right"]),
        })]),
        onClick: (x) => {
            x.preventDefault();
            setNextIndex();
            next();
        },
    });
}

export function PreviewAuctionsBar(previewAuctionsBarInputProps) {
    const setIndex = previewAuctionsBarInputProps.setIndex;
    const index = previewAuctionsBarInputProps.index;
    const auctions = previewAuctionsBarInputProps.auctions;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const nextIndex = (((index + 1) === length(auctions)) ? 0 : (index + 1)) | 0;
    const prevIndex = (((index - 1) < 0) ? (length(auctions) - 1) : (index - 1)) | 0;
    const ref = useReact_useRef_1505(void 0);
    return createElement("div", createObj(toList(delay(() => {
        const slideToShow = (count) => {
            if (length(auctions) < count) {
                return length(auctions) | 0;
            }
            else {
                return count | 0;
            }
        };
        const breakpoints = [new Responsive(1216, new Settings(slideToShow(4))), new Responsive(1023, new Settings(slideToShow(4))), new Responsive(821, new Settings(slideToShow(3))), new Responsive(766, new Settings(slideToShow(2)))];
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", {
            children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(react_slick, {
                ref: ref,
                dots: true,
                speed: 250,
                centerMode: false,
                arrows: true,
                swipeToSlide: true,
                slidesToShow: slideToShow(4),
                responsive: breakpoints,
                prevArrow: createElement(PrevArrow, {
                    prev: () => {
                        iterate((x_1) => {
                            x_1.slickPrev();
                        }, toArray(ref.current));
                    },
                    setNextIndex: () => {
                        setIndex(prevIndex);
                    },
                }),
                nextArrow: createElement(NextArrow, {
                    next: () => {
                        iterate((x_2) => {
                            x_2.slickNext();
                        }, toArray(ref.current));
                    },
                    setNextIndex: () => {
                        setIndex(nextIndex);
                    },
                }),
                afterChange: (x) => {
                    switch (width.tag) {
                        case 0:
                        case 1:
                        case 2: {
                            setIndex(x);
                            break;
                        }
                        default: {
                        }
                    }
                },
                children: toList(delay(() => collect((matchValue) => singleton(createElement(PreviewAuctionsBarItem, {
                    auction: matchValue[1],
                    index: index,
                    ind: matchValue[0],
                    setIndex: setIndex,
                })), indexed(auctions)))),
            })]),
        })])]);
    }))));
}

export function NewRenderAuction(multipleAuctions) {
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const index = patternInput[0] | 0;
    const auctions = getAuctions(multipleAuctions);
    const auction = defaultArg(map_1((tuple) => tuple[1], tryFind((tupledArg) => (tupledArg[0] === index), indexed(append(multipleAuctions.ActiveAuctions, multipleAuctions.HistoryAuctions)))), AuctionPreviewInfo_get_Empty());
    return createElement("div", {
        className: "auctionBox",
        children: Interop_reactApi.Children.toArray([mainAuctionRender(auction), createElement(PreviewAuctionsBar, {
            auctions: auctions,
            index: index,
            setIndex: patternInput[1],
        })]),
    });
}

export function Page(pageInputProps) {
    let props;
    const user = pageInputProps.user;
    const auctions = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getActiveAndHistoryAuctions(), [user]);
    switch (auctions.tag) {
        case 1: {
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([(props = ofArray([["className", join(" ", ["home-box-container", "home-info", "is-mobile", "is-fluid", "has-text-centered", "home-loader"])], ["children", Interop_reactApi.Children.toArray([largeGreen])]]), createElement("div", createObj(Helpers_combineClasses("container", props))))]),
            });
        }
        case 3: {
            const props_3 = ofArray([["className", "is-danger"], ["children", auctions.fields[0].message]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props_3)));
        }
        case 2: {
            const auc = auctions.fields[0];
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement(NewRenderAuction, auc), createElement(ViewHomeInfo, {
                    user: user,
                    auc: auc,
                }), customBlock]),
            });
        }
        default: {
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([(props = ofArray([["className", join(" ", ["home-box-container", "home-info", "is-mobile", "is-fluid", "has-text-centered", "home-loader"])], ["children", Interop_reactApi.Children.toArray([largeGreen])]]), createElement("div", createObj(Helpers_combineClasses("container", props))))]),
            });
        }
    }
}

