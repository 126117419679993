import { createElement } from "react";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "./Localization.js";
import { Body } from "./Haraldssons/HaraldssonsTerms.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function modalWindow(par, setVisible) {
    return createElement("div", {
        className: join(" ", ["modal", par]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "modal-background",
            onClick: (_arg1) => {
                setVisible("");
            },
        }), createElement("div", {
            className: join(" ", ["modal-card", "modal_cookies_terms"]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["modal-card-head", "has-background-white", "is-family-secondary"]),
                style: {
                    borderBottomStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["modal-card-title", "is-uppercase", "has-background-white", "has-text-black"]),
                    children: t("termsAndConditions.title"),
                }), createElement("button", {
                    className: "delete",
                    onClick: (_arg2) => {
                        setVisible("");
                    },
                })]),
            }), createElement("div", {
                style: {
                    paddingTop: 20,
                },
                className: join(" ", ["modal-card-body", "has-background-white", "has-text-grey-darker"]),
                children: Interop_reactApi.Children.toArray([createElement(Body, null)]),
            })]),
        })]),
    });
}

export function TermsAndConditions() {
    let props;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setVisible = patternInput[1];
    return createElement("div", {
        className: "footer-buttons-container",
        children: Interop_reactApi.Children.toArray([(props = ofArray([["className", join(" ", ["button", "is-dark", "is-uppercase", "is-fullwidth", "footer-button"])], ["children", t("termsAndConditions.title")], ["onClick", (_arg1) => {
            setVisible("is-active");
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props)))), modalWindow(patternInput[0], setVisible)]),
    });
}

