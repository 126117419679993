import { t } from "../Localization.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Img_suitcaseHaraldssons, Img_contactBackgroundHaraldssons } from "../Css.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { View } from "../Users/AppointmentBooking.js";

export function p(key) {
    return t("haraldssons.sell." + key);
}

export function Render(renderInputProps) {
    let props, props_31, props_29, props_9, props_27, children_4, props_15, elms, elms_1, props_21, elms_2, elms_3;
    const user = renderInputProps.user;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "previewImageCenterWithoutRatio",
            style: {
                display: "flex",
                justifyContent: "center",
                backgroundImage: ("url(\u0027" + Img_contactBackgroundHaraldssons) + "\u0027)",
                minHeight: 400,
                alignItems: "center",
                textAlign: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["buyBox"]),
                style: {
                    textAlign: "center",
                    display: "grid",
                    justifyContent: "center",
                },
                children: Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-2"], ["className", "has-text-white"], ["className", "homeTitleAuction"], ["children", p("head.title")]]), createElement("h1", createObj(Helpers_combineClasses("title", props))))]),
            })]),
        }), (props_31 = ofArray([["style", {
            whiteSpace: "pre-line",
        }], ["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["className", join(" ", ["buy-content-box"])], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-9"], ["className", "is-8-fullhd"], ["className", "is-8-widescreen"], ["className", "is-9-desktop"], ["className", "is-11-tablet"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: "buyBox",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                className: join(" ", ["buy-subtitle"]),
                children: p("section1.text"),
            })]),
        }), createElement("div", {
            className: "buyBox",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("section1.title"),
                className: join(" ", ["buy-title", "homeTitleAuction"]),
            })]),
        }), createElement("fieldset", {
            children: Interop_reactApi.Children.toArray([createElement(View, {
                user: user,
                isSeparatePage: false,
            })]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9)))), (props_27 = singleton(["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["buyContainer"]),
            style: {
                padding: 40,
            },
            children: Interop_reactApi.Children.toArray([createElement("p", {
                className: join(" ", ["is-size-3-mobile", "is-size-2", "padding-bottom-desktop15-mobile10", "buy-title", "homeTitleAuction"]),
                children: p("box.title"),
            }), (children_4 = ofArray([createElement("li", {
                className: join(" ", ["buy-table-list", "buyBorder", "fontFamiliLato"]),
                children: Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(elms = singleton(p("box.table.1.1")), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (elms_1 = singleton(createElement("span", {
                    children: p("box.table.1.2"),
                    style: {
                        float: "right",
                        textAlign: "right",
                    },
                })), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_15))))]),
            }), createElement("li", {
                className: join(" ", ["buy-table-list", "buyBorder", "fontFamiliLato"]),
                children: Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton(p("box.table.2.1")), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                })), (elms_3 = singleton(createElement("span", {
                    children: p("box.table.2.2"),
                    style: {
                        float: "right",
                        textAlign: "right",
                    },
                })), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_21))))]),
            })]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))]),
        }), createElement("div", {
            style: {
                backgroundImage: ("url(\u0027" + Img_suitcaseHaraldssons) + "\u0027)",
            },
            className: join(" ", ["sellContainerImage"]),
        })])]), createElement("div", createObj(Helpers_combineClasses("column", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_29))))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_31))))]),
    });
}

